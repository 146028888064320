<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems" />
    <div class="text-center">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-6 col-6">
          <div class="card">
            <div class="card-body">
              <h3 class="text-danger">
                {{ newTickets || 0 }}
              </h3>
              <p class="text-uppercase mb-1 font-13 font-weight-medium">
                {{ $t("New Tickets") || 0 }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-6">
          <div class="card">
            <div class="card-body">
              <h3 class="" style="color: orange">
                {{ open || 0 }}
              </h3>
              <p class="text-uppercase mb-1 font-13 font-weight-medium">
                {{ $t("Open Tickets") }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-6">
          <div class="card">
            <div class="card-body">
              <h3 class="text-warning">
                {{ pending || 0 }}
              </h3>
              <p class="text-uppercase mb-1 font-13 font-weight-medium">
                {{ $t("Pending Tickets") }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-6 col-6">
          <div class="card">
            <div class="card-body">
              <h3 class="text-success">
                {{ resolved || 0 }}
              </h3>
              <p class="text-uppercase mb-1 font-13 font-weight-medium">
                {{ $t("Resolved Tickets") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row mb-3 align-items-end">
          <div class="col-lg-2 col-md-6 col-12">
            <div class="form-group m-0">
              <div class="w-100 text-left">
                <label class="justify-content-start">{{ $t("Status") }}:</label>
              </div>
              <MultiSelectInput
                :key="param.status.map((item) => item.id).join('-')"
                @input="filterTickets()"
                v-model="param.status"
                label="title"
                trackBy="id"
                :multiple="true"
                :options="statusOptions || []"
                open-direction="bottom"
              />
            </div>
          </div>
          <div class="col-lg-2 col-md-6 col-12">
            <div class="form-group m-0">
              <div class="w-100 text-left">
                <label class="justify-content-start"
                  >{{ $t("Priority") }}:</label
                >
              </div>
              <MultiSelectInput
                :key="param.priority.map((item) => item.id).join('-')"
                @input="filterTickets()"
                v-model="param.priority"
                label="title"
                trackBy="id"
                :multiple="true"
                :options="priorityOptions"
                open-direction="bottom"
              />
            </div>
          </div>
          <div class="col-lg-4 d-md-flex align-items-center gap-2 mt-1 mt-lg-0">
            <form class="w-100">
              <div class="position-relative">
                <b-form-input
                  v-model="param.search"
                  type="search"
                  :placeholder="$t('Enter Text')"
                  class="form-control"
                  @input="debouncedSearch"
                ></b-form-input>
                <span
                  v-if="param.search"
                  class="position-absolute top-50 end-0 translate-middle-y pe-3"
                  style="cursor: pointer"
                  @click="param.search = ''"
                >
                  &times;
                </span>
              </div>
            </form>
            <button
              @click="
                selectedTicketIds.length > 0
                  ? (toggleEditModal = true)
                  : (toggleEditModal = false)
              "
              :disabled="!selectedTicketIds.length"
              class="btn btn-primary text-nowrap ms-1"
            >
              {{ $t("Bulk Edit") }}
            </button>
          </div>
        </div>
        <div class="tickets-table table-responsive mb-0">
          <vue-good-table
            :key="'parent'"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
            :columns="showLocalColumns"
            :fixed-header="false"
            mode="remote"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage: dataCount,
            }"
            styleClass="vgt-table condensed"
          >
            <template slot="table-row" slot-scope="props">
              <span
                class="d-flex align-items-center justify-content-start gap-2"
                v-if="props.column.field === 'action'"
              >
                <div
                  class="cursor-pointer"
                  @click="$router.push(`/tickets/${props.row.id}/edit`)"
                >
                  <feather-icon size="16" icon="Edit2Icon" />
                </div>
                <div class="cursor-pointer" @click="destroy(props.row.id)">
                  <feather-icon size="16" icon="TrashIcon" />
                </div>
              </span>
              <span v-else class="custom-pointer">
                <span
                  v-if="props.column.field === 'status'"
                  class="badge"
                  :class="{
                    'badge-danger':
                      props.formattedRow[props.column.field] === 'new',
                    'badge-secondary':
                      props.formattedRow[props.column.field] === 'pending',
                    'badge-orange':
                      props.formattedRow[props.column.field] === 'open',
                    'badge-success':
                      props.formattedRow[props.column.field] === 'resolved',
                    'badge-warning':
                      props.formattedRow[props.column.field] ===
                      'waiting-for-response',
                  }"
                >
                  {{ props.formattedRow[props.column.field] }}</span
                >

                <span
                  v-else-if="props.column.field === 'priority'"
                  class="badge"
                  :class="{
                    'badge-secondary':
                      props.formattedRow[props.column.field] === 'low',
                    'badge-danger':
                      props.formattedRow[props.column.field] === 'high',
                    'badge-success':
                      props.formattedRow[props.column.field] === 'normal',
                  }"
                >
                  {{ props.formattedRow[props.column.field] }}</span
                >
                <span v-else-if="props.column.field === 'ticketNumber'">
                  <span>
                    <input
                      class="mr-2"
                      type="checkbox"
                      :checked="isSelected(props.row.id)"
                      @change="toggleSelection(props.row.id)"
                    />
                  </span>
                  {{ props.formattedRow[props.column.field] }}
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </span>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-end flex-wrap">
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="totalRecords"
                    :per-page="dataCount"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value) => onPageChange({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
        <BulkEditModal
          :toggleModal="toggleEditModal"
          :selectedTicketIds="selectedTicketIds"
          @toggleModal="toggleEditModal = $event"
          @listUpdate="
            selectedTicketIds = [];
            fetchTickets();
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import BulkEditModal from "@/components/ticketSystem/BulkEditModal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PageHeader,
    MultiSelectInput,
    BulkEditModal,
  },
  data() {
    return {
      totalRecords: 0,
      module: "tickets",
      isTableConfig: false,
      title: this.$t("Tickets"),
      toggleEditModal: false,
      toggleModal: false,
      selectedTicketFilters: null,
      newTickets: 0,
      open: 0,
      pending: 0,
      resolved: 0,
      param: {
        userId: "",
        status: [],
        priority: [],
        search: "",
      },
      dataCount: 100,
      currentPage: 1,
      // perPage: 100,
      // search: "",
      order: "desc",
      column: "id",
      sortBy: "id",
      selectedPriority: null,
      selectedStatus: null,
      area: "all",
      isLoading: false,
      rows: [],
      savedFilters: [],
      selectedTicketIds: [],

      debounceTimeout: null,
    };
  },
  computed: {
    ...mapGetters(["showLoader"]),
    ...mapGetters("tickets", ["tickets"]),
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create Ticket"),
          icon: "PlusIcon",
          path: "/tickets/create",
          permission: "ticket.create",
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Tickets"),
          active: true,
        },
      ];
    },
    statusOptions() {
      return [
        {
          title: this.$t("New"),
          id: "new",
        },
        {
          title: this.$t("Open"),
          id: "open",
        },

        {
          title: this.$t("Waiting For Response"),
          id: "waiting-for-response",
        },
        {
          title: this.$t("Pending"),
          id: "pending",
        },
        {
          title: this.$t("Resolved"),
          id: "resolved",
        },
      ];
    },
    priorityOptions() {
      return [
        {
          title: this.$t("Low"),
          id: "low",
        },
        {
          title: this.$t("Normal"),
          id: "normal",
        },
        {
          title: this.$t("High"),
          id: "high",
        },
      ];
    },
    showLocalColumns() {
      return [
        {
          label: this.$t("Ticket Number"),
          field: "ticketNumber",
          table: "tickets",
          dbColName: "ticketNumber",
          tableColumn: "ticket_number",
          colType: "string",
        },
        {
          label: this.$t("Title"),
          field: "title",
          table: "tickets",
          dbColName: "title",
          tableColumn: "title",
          colType: "string",
        },
        {
          label: this.$t("Priority"),
          field: "priority",
          table: "tickets",
          dbColName: "priority",
          tableColumn: "priority",
          colType: "multiselect",
          loadMore: false,
          moduleName: "",
        },
        {
          label: this.$t("Status"),
          field: "status",
          table: "tickets",
          dbColName: "status",
          tableColumn: "status",
          colType: "multiselect",
          loadMore: false,
          moduleName: "",
        },
        {
          label: this.$t("Action"),
          field: "action",
          sortable: false, // disable sorting on this column
          action: true,
        },
      ];
    },
  },
  async mounted() {
    await this.fetchTickets();
  },
  methods: {
    debouncedSearch() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(async () => {
        await this.fetchTickets();
      }, 300);
    },

    isSelected(rowId) {
      return this.selectedTicketIds.includes(rowId);
    },
    toggleSelection(rowId) {
      const index = this.selectedTicketIds.indexOf(rowId);
      if (index === -1) {
        // Row ID does not exist, so push it to the array
        this.selectedTicketIds.push(rowId);
      } else {
        // Row ID exists, so remove it from the array
        this.selectedTicketIds.splice(index, 1);
      }
    },
    async filterTickets() {
      await this.$nextTick();
      await this.fetchTickets();
    },
    async destroy(id) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store.dispatch("tickets/destroy", id);
          this.fetchTickets();
        }
      });
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.fetchTickets();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.fetchTickets();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.fetchTickets();
    },
    async fetchTickets() {
      let params = {
        perPage: this.dataCount,
        sortOrder: this.order,
        sortBy: this.sortBy,
        search: this.param.search,
        status:
          this.param.status.map((item) => {
            return item.id;
          }) || [],
        priority:
          this.param.priority.map((item) => {
            return item.id;
          }) || [],
        area: this.area,
        filterByText: this.filterByText,
        isAdminPortal: true,
      };
      try {
        this.$store.commit("showLoader", true);
        const response = await this.$store.dispatch("tickets/list", {
          ...params,
        });
        this.newTickets = response?.data?.new;
        this.open = response?.data?.open;
        this.pending = response?.data?.pending;
        this.resolved = response?.data.resolved;
        this.rows = this.tickets;
        this.totalRecords = this.tickets?.length || 0;
        console.log(this.rows);
      } catch (error) {
        console.error(e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
  },
};
</script>
